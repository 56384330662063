<template>
  <div>
    <div v-if="selectedAssetGroup._id || selectedAsset._id">
      <div class="row mb-4">
        <div v-if="availableAccountTypes.includes('electricity')" class="col">
          <ConsumptionCard
            :value="analytics['electricity-consumption']?.results?.netTotalCost"
            :prev-value="analytics['electricity-consumption-prev']?.results?.netTotalCost"
            precision="2"
            icon-class="fa-plug text-warning"
            description="Electricity"
            units="GBP"
            prefix="£ "
            :comparison-period="analyticsOptions.comparePeriod"
            class="flex-grow-1"
            :value-loading="loadingAction.getAnalytics['electricity-consumption']"
            :prev-value-loading="loadingAction.getAnalytics['electricity-consumption-prev']"
            :error="errorAction.getAnalytics['electricity-consumption']"
            :estimated-percentage="
              (analytics['electricity-consumption']?.results?.estimatedNetTotalCost * 100) /
              analytics['electricity-consumption']?.results?.netTotalCost
            "
          />
        </div>
        <div v-if="availableAccountTypes.includes('gas')" class="col">
          <ConsumptionCard
            :value="analytics['gas-consumption']?.results?.netTotalCost"
            :prev-value="analytics['gas-consumption-prev']?.results?.netTotalCost"
            precision="2"
            icon-class="fa-flame text-danger"
            description="Gas"
            units="GBP"
            prefix="£ "
            :comparison-period="analyticsOptions.comparePeriod"
            class="flex-grow-1"
            :value-loading="loadingAction.getAnalytics['gas-consumption']"
            :prev-value-loading="loadingAction.getAnalytics['gas-consumption-prev']"
            :error="errorAction.getAnalytics['gas-consumption']"
            :estimated-percentage="
              (analytics['gas-consumption']?.results?.estimatedNetTotalCost * 100) / analytics['gas-consumption']?.results?.netTotalCost
            "
          />
        </div>
        <div v-if="availableAccountTypes.includes('water')" class="col">
          <ConsumptionCard
            :value="analytics['water-consumption']?.results?.netTotalCost"
            :prev-value="analytics['water-consumption-prev']?.results?.netTotalCost"
            precision="2"
            icon-class="fa-water text-info"
            description="Water"
            units="GBP"
            prefix="£ "
            :comparison-period="analyticsOptions.comparePeriod"
            class="flex-grow-1"
            :value-loading="loadingAction.getAnalytics['water-consumption']"
            :prev-value-loading="loadingAction.getAnalytics['water-consumption-prev']"
            :error="errorAction.getAnalytics['water-consumption']"
            :estimated-percentage="
              (analytics['water-consumption']?.results?.estimatedNetTotalCost * 100) / analytics['water-consumption']?.results?.netTotalCost
            "
          />
        </div>
        <div v-if="availableAccountTypes.includes('waste')" class="col">
          <ConsumptionCard
            :value="analytics['waste-consumption']?.results?.netTotalCost"
            :prev-value="analytics['waste-consumption-prev']?.results?.netTotalCost"
            precision="2"
            icon-class="fa-waste"
            description="Waste"
            units="GBP"
            prefix="£ "
            :comparison-period="analyticsOptions.comparePeriod"
            class="flex-grow-1"
            :value-loading="loadingAction.getAnalytics['waste-consumption']"
            :prev-value-loading="loadingAction.getAnalytics['waste-consumption-prev']"
            :error="errorAction.getAnalytics['waste-consumption']"
            :estimated-percentage="
              (analytics['waste-consumption']?.results?.estimatedNetTotalCost * 100) / analytics['waste-consumption']?.results?.netTotalCost
            "
          />
        </div>
        <div v-if="availableAccountTypes.includes('pv')" class="col">
          <ConsumptionCard
            :value="analytics['pv-consumption']?.results?.netTotalCost"
            :prev-value="analytics['pv-consumption-prev']?.results?.netTotalCost"
            precision="2"
            icon-class="fa-solar-panel"
            description="PV"
            units="GBP"
            prefix="£ "
            :comparison-period="analyticsOptions.comparePeriod"
            class="flex-grow-1"
            :value-loading="loadingAction.getAnalytics['pv-consumption']"
            :prev-value-loading="loadingAction.getAnalytics['pv-consumption-prev']"
            :error="errorAction.getAnalytics['pv-consumption']"
            :estimated-percentage="
              (analytics['pv-consumption']?.results?.estimatedNetTotalCost * 100) / analytics['pv-consumption']?.results?.netTotalCost
            "
          />
        </div>
        <div class="col">
          <ConsumptionCard
            :value="total.netTotalCost"
            :prev-value="total.prevNetTotalCost"
            precision="2"
            icon-class="fa-sum"
            description="Total"
            units="GBP"
            prefix="£ "
            :comparison-period="analyticsOptions.comparePeriod"
            class="flex-grow-1"
            :value-loading="loadingAction.getAnalytics['electricity-consumption']"
            :prev-value-loading="loadingAction.getAnalytics['electricity-consumption-prev']"
            :error="errorAction.getAnalytics['electricity-consumption']"
            :estimated-percentage="total.estimatedNetTotalCost / total.netTotalCost"
          />
        </div>
      </div>

      <div class="row">
        <div class="col-xl-12">
          <Block>
            <ChartCard
              title="Invoice Consumption"
              :loading="loadingAction.getAnalytics[`${options.costGraph.accountType}-consumption`]"
              :error="errorAction.getAnalytics[`${options.costGraph.accountType}-consumption`]"
            >
              <template v-slot:actions>
                <div class="row align-items-center justify-content-end">
                  <!-- <div class="col-md-6">
                    <FormItem
                      id="showEstimated"
                      v-model="options.showEstimated"
                      label="Type"
                      class="mb-0"
                      type="radio"
                      :is-alt="true"
                      size="sm"
                      :options="[
                        { label: 'Simple', value: false },
                        { label: 'Estimated', value: true }
                      ]"
                    />
                  </div> -->

                  <div class="col-md-8">
                    <FormItem
                      id="consumptionType"
                      v-model="options.costGraph.type"
                      label="Type"
                      class="mb-0"
                      type="radio"
                      :is-alt="true"
                      size="sm"
                      :options="[
                        { label: 'vs Prev Period', value: 'default' },
                        { label: 'Estimated Data', value: 'estimated' },
                        { label: 'Landlord/Tenant', value: 'meterUser' }
                      ]"
                    />
                  </div>

                  <div class="col-md-4">
                    <FormItem
                      id="consumptioAccountType"
                      v-model="options.costGraph.accountType"
                      label="Type"
                      class="mb-0"
                      type="select"
                      :is-alt="true"
                      size="sm"
                      :options="
                        availableAccountTypes
                          .map(type => ({
                            label: capitalize(type),
                            value: type
                          }))
                          .concat({
                            label: 'All',
                            value: ''
                          })
                      "
                    />
                  </div>
                </div>
              </template>

              <template>
                <apexchart height="350" :options="consumptionChart.options" :series="consumptionChart.series" />
              </template>
            </ChartCard>
          </Block>
        </div>
      </div>
    </div>
    <div v-else-if="!selectedAssetGroup._id && !selectedAsset._id">
      <div class="mt-5 text-center alert alert-warning">Select an asset/site, portfolio or fleet above to view analytics.</div>
    </div>
    <div v-else>
      <div class="mt-5 text-center">
        <div class="spinner-border spinner-lg text-secondary mb-4" role="status">
          <span class="sr-only">Loading...</span>
        </div>
        <div class="font-w600">Loading...</div>
      </div>
    </div>
  </div>
</template>
<script>
import { uniqBy } from 'lodash';
import moment from 'moment';
import { mapActions, mapGetters, mapMutations } from 'vuex';

import Block from '@/components/analytics/Block';
import ChartCard from '@/components/analytics/ChartCard';
import ConsumptionCard from '@/components/analytics/ConsumptionCard';
import FormItem from '@/components/FormItem';

import { capitalize } from '@/lib/helpers';

export default {
  name: 'AnalyticsCosts',
  components: {
    Block,
    ChartCard,
    ConsumptionCard,
    FormItem
  },
  data() {
    return {
      options: {
        accountId: '',
        costGraph: {
          type: 'default',
          accountType: 'electricity'
        }
      }
    };
  },
  computed: {
    ...mapGetters({
      assetGroup: 'analytics/selectedAssetGroup',
      asset: 'analytics/selectedAsset',
      assets: 'analytics/assets',
      analytics: 'analytics/analytics',
      analyticsOptions: 'analytics/options',
      loadingAction: 'analytics/loadingAction',
      errorAction: 'analytics/errorAction',
      selectedAsset: 'analytics/selectedAsset',
      selectedAssetGroup: 'analytics/selectedAssetGroup',
      periodComparisonParams: 'analytics/periodComparisonParams'
    }),
    total() {
      const total = {
        netTotalCost: 0,
        estimatedNetTotalCost: 0,
        prevNetTotalCost: 0
      };

      this.availableAccountTypes.forEach(type => {
        const netTotalCost = this.analytics[`${type}-consumption`]?.results?.netTotalCost;
        const estimatedNetTotalCost = this.analytics[`${type}-consumption`]?.results?.estimatedNetTotalCost;
        const prevNetTotalCost = this.analytics[`${type}-consumption-prev`]?.results?.netTotalCost;

        total.netTotalCost += netTotalCost;
        total.estimatedNetTotalCost += estimatedNetTotalCost;
        total.prevNetTotalCost += prevNetTotalCost;
      });

      return total;
    },
    availableAccountTypes() {
      return ['electricity', 'gas', 'water'];
    },
    consumptionChart() {
      // Periods
      const allConsumptionPeriods = this.availableAccountTypes.reduce(
        (consumptions, type) => [...consumptions, ...(this.analytics[`${type}-consumption`]?.results?.monthlyConsumption || [])],
        []
      );
      allConsumptionPeriods.sort((a, b) => new Date(a.startDate) - new Date(b.startDate));
      const uniquePeriods = uniqBy(allConsumptionPeriods, 'period').map(c => c.period);

      // Single Series Data
      let series = [
        {
          name: 'Prev Period',
          data: (this.analytics[`${this.options.costGraph.accountType}-consumption-prev`]?.results?.monthlyConsumption || []).map(c =>
            c.netTotalCost.toFixed(2)
          )
        },
        {
          name: 'Current Period',
          data: (this.analytics[`${this.options.costGraph.accountType}-consumption`]?.results?.monthlyConsumption || []).map(c =>
            c.netTotalCost.toFixed(2)
          )
        }
      ];

      // Single Series Data - With Tenant/Landlord

      // Split Series Data
      if (!this.options.costGraph.accountType) {
        series = this.availableAccountTypes.map(type => ({
          name: capitalize(type),
          data: uniquePeriods.map(period => {
            const consumptionInPeriod = (this.analytics[`${type}-consumption`]?.results?.monthlyConsumption || []).find(c => c.period === period);
            return consumptionInPeriod ? consumptionInPeriod.netTotalCost.toFixed(2) : 0;
          })
        }));
      }

      if (this.options.costGraph.type === 'estimated') {
        series = [
          {
            name: 'Actual',
            data: (this.analytics[`${this.options.costGraph.accountType}-consumption`]?.results?.monthlyConsumption || []).map(c =>
              (c.netTotalCost - c.estimatedNetTotalCost).toFixed(0)
            )
          },
          {
            name: 'Estimated',
            data: (this.analytics[`${this.options.costGraph.accountType}-consumption`]?.results?.monthlyConsumption || []).map(c =>
              c.estimatedNetTotalCost.toFixed(0)
            )
          }
        ];
      }

      if (this.options.costGraph.type === 'meterUser') {
        series = [
          {
            name: 'Landlord',
            data: (this.analytics[`${this.options.costGraph.accountType}-consumption`]?.results?.monthlyConsumption || []).map(c =>
              (c.netTotalCost - c.tenantNetTotalCost).toFixed(0)
            )
          },
          {
            name: 'Tenant',
            data: (this.analytics[`${this.options.costGraph.accountType}-consumption`]?.results?.monthlyConsumption || []).map(c =>
              c.tenantNetTotalCost.toFixed(0)
            )
          }
        ];
      }

      // Chart Options
      return {
        series,
        options: {
          chart: {
            type: 'bar',
            stacked: ['estimated', 'meterUser'].includes(this.options.costGraph.type)
          },
          colors: this.options.costGraph.accountType
            ? [this.defaultChartOptions.colors[1], this.defaultChartOptions.colors[0]]
            : this.defaultChartOptions.colors,
          xaxis: {
            categories: uniquePeriods.map(p => moment(p, 'YYYY-MM').format('MMM YYYY'))
          },
          dataLabels: {
            enabled: false
          }
        }
      };
    },
    defaultChartOptions() {
      return {
        title: {
          text: ''
        },
        xAxis: {
          type: 'datetime',
          dateTimeLabelFormats: {
            month: '%b',
            year: '%b'
          },
          title: {
            text: 'Date'
          }
        },
        yAxis: {
          title: {
            text: 'Label'
          },
          min: 0
        },
        tooltip: {
          headerFormat: '<b>{series.name}</b><br>',
          pointFormat: '{point.name}: {point.y}'
        },
        plotOptions: {
          series: {
            marker: {
              enabled: true
            }
          }
        },
        colors: ['#65C198', '#1F303D', '#F25F5C', '#FFE066', '#1B98E0', '#727272', '#db6edc', '#945ab9', '#25714e', '#941d1c', '#977911', '#045889'],
        series: [],
        responsive: {
          rules: [
            {
              condition: {
                maxWidth: 500
              },
              chartOptions: {
                plotOptions: {
                  series: {
                    marker: {
                      radius: 2.5
                    }
                  }
                }
              }
            }
          ]
        },
        credits: {
          enabled: false
        }
      };
    }
  },
  methods: {
    ...mapActions({
      getAssetHHData: 'analytics/getAssetHHData'
    }),
    ...mapMutations({
      setOption: 'analytics/SET_OPTION'
    }),
    capitalize,
    csvExport(arrData) {
      let csvContent = 'data:text/csv;charset=utf-8,';
      csvContent += [Object.keys(arrData[0]).join(';'), ...arrData.map(item => Object.values(item).join(';'))]
        .join('\n')
        .replace(/(^\[)|(\]$)/gm, '');

      const data = encodeURI(csvContent);
      const link = document.createElement('a');
      link.setAttribute('href', data);
      link.setAttribute('download', 'export.csv');
      link.click();
    },
    onChangeHHGraphPeriod(value) {
      this.setOption({
        key: 'hhGraphPeriod',
        option: value
      });

      this.getAssetHHData();
    }
  }
};
</script>
